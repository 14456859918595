import React, { useState } from 'react';
import { Modal, Input, Button, message } from 'antd';
import { auth, signInAnonymously, firestore, doc, setDoc, getDoc } from '../firebase';
import './UserAuth.css';

const offensiveWords = ['nigger', 'fuck', 'shit', 'bitch', 'cunt', 'asshole', 'dick', 'pussy', 'slut', 'whore'];

const isUsernameValid = (username) => {
  const isNumeric = /^\d+$/.test(username);
  const isOffensive = offensiveWords.some(word => username.toLowerCase().includes(word));
  const isTooLong = username.length > 32;
  return !isNumeric && !isOffensive && !isTooLong;
};

const UserAuth = ({ setUsername }) => {
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [usernameValid, setUsernameValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOk = async () => {
    if (!isUsernameValid(name)) {
      setUsernameValid(false);
      if (name.length > 32) {
        setErrorMessage('This username is too long. Please choose another username.');
      } else {
        setErrorMessage('You are using a numeric-only name or you typed an offensive word. Please choose another username.');
      }
      return;
    }
    setUsernameValid(true);

    if (name.trim() && password.trim()) {
      const userRef = doc(firestore, 'users', name);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        if (isExistingUser) {
          const userData = userSnap.data();
          if (userData.password === password) {
            await signInAnonymously(auth);
            setUsername(name);
            setVisible(false);
          } else {
            message.error('Incorrect password. Please try again.');
          }
        } else {
          message.error('This name is taken. Please use the password you set when you first used the name or use another username.');
        }
      } else {
        const userCredential = await signInAnonymously(auth);
        await setDoc(userRef, { uid: userCredential.user.uid, name, points: 0, password });
        setUsername(name);
        setVisible(false);
      }
    } else {
      message.error('Please enter both a username and password.');
    }
  };

  const handleUsernameChange = async (e) => {
    const value = e.target.value;
    setName(value);

    if (!isUsernameValid(value)) {
      setUsernameValid(false);
      if (value.length > 32) {
        setErrorMessage('This username is too long. Please choose another username.');
      } else {
        setErrorMessage('You are using a numeric-only name or you typed an offensive word. Please choose another username.');
      }
    } else {
      setUsernameValid(true);
      setErrorMessage('');
      if (value.trim()) {
        const userRef = doc(firestore, 'users', value);
        const userSnap = await getDoc(userRef);
        setIsExistingUser(userSnap.exists());
      } else {
        setIsExistingUser(false);
      }
    }
  };

  return (
    <Modal title="Enter Username and Password" visible={visible} footer={null} closable={false}>
      <Input placeholder="Username" value={name} onChange={handleUsernameChange} />
      <Input.Password placeholder={isExistingUser ? "Enter your password" : "Set a password"} value={password} onChange={(e) => setPassword(e.target.value)} disabled={!usernameValid} />
      <Button type="primary" onClick={handleOk} style={{ marginTop: '10px' }} disabled={!usernameValid}>
        Submit
      </Button>
      {!usernameValid && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </Modal>
  );
};

export default UserAuth;
