import React from 'react';
import { Button } from 'antd';
import { auth, signOut } from '../firebase';
import './UserMenu.css';

const UserMenu = ({ username }) => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload(); // Reload the page to clear the state
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div className="user-menu">
      {username && (
        <>
          <span className="welcome-message">Welcome, {username}</span>
          <Button type="primary" onClick={handleLogout}>
            Logout
          </Button>
        </>
      )}
    </div>
  );
};

export default UserMenu;
