
import React from 'react';
import { Layout } from 'antd';
import './Footer.css';

const { Footer } = Layout;

const AppFooter = () => (
  <Footer className="footer">
    💖Product created with love by Cania's dev, Lowkey. ©2024 Cania Limited
  </Footer>
);

export default AppFooter;
