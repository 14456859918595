import React, { useState } from 'react';
import { Button } from 'antd';
import { useSpring, animated } from 'react-spring';
import './ClickerButton.css';

const ClickerButton = ({ onClick, points }) => {
  const [hearts, setHearts] = useState([]);
  const [nextId, setNextId] = useState(0);

  const handleClick = () => {
    onClick();

    const id = nextId;
    setNextId(id + 1);

    // Generate random positions for the heart
    const startX = Math.random() * 100;
    const endX = Math.random() * 100;

    // Create a new heart animation
    setHearts([...hearts, { id, startX, endX }]);

    // Remove the heart animation after it fades out
    setTimeout(() => {
      setHearts(hearts => hearts.filter(heart => heart.id !== id));
    }, 2000);
  };

  const props = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { duration: 500 }
  });

  return (
    <animated.div style={props}>
      <Button className="clicker-button" type="primary" shape="round" size="large" onClick={handleClick}>
        💖 Click to send love!
      </Button>
      <p className="points-display">Total Love Points: {points}</p>
      <p className="points-display">Send some love , its free</p>
      <div className="hearts-container">
        {hearts.map(heart => (
          <animated.div
            key={heart.id}
            className="heart"
            style={{
              left: `${heart.startX}%`,
              animationName: 'floatUp'
            }}
          >
            ❤️
          </animated.div>
        ))}
      </div>
    </animated.div>
  );
};

export default ClickerButton;
