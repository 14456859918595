import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import AppHeader from './components/Header';
import ClickerButton from './components/ClickerButton';
import Leaderboard from './components/Leaderboard';
import AppFooter from './components/Footer';
import UserAuth from './components/UserAuth';
import UserMenu from './components/UserMenu';
import InstagramButton from './components/InstagramButton';
import { auth, firestore, doc, setDoc, updateDoc, getDoc, onSnapshot, collection } from './firebase';
import './App.css';

const { Content } = Layout;

const App = () => {
  const [points, setPoints] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [username, setUsername] = useState('');
  useEffect(() => {
    // Set the title and description when the component mounts
    document.title = "We love you too";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "love.cania.ro is a website for you to show your love twords our initiative");
    } else {
      // If the meta description does not exist, create it and append to the head
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.setAttribute('name', 'description');
      newMetaDescription.setAttribute('content', "love.cania.ro is a website for you to show your love twords our initiative");
      document.head.appendChild(newMetaDescription);
    }
  }, []);
  const handleClick = async () => {
    setPoints(points + 1);
    const userRef = doc(firestore, 'users', username);
    await updateDoc(userRef, { points: points + 1 });
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, 'users'), (snapshot) => {
      const leaderboardData = snapshot.docs.map((doc) => doc.data());
      setLeaderboard(leaderboardData);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const checkExistingUser = async () => {
      if (auth.currentUser) {
        const userRef = doc(firestore, 'users', username);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setPoints(userSnap.data().points);
          setUsername(userSnap.data().name);
        }
      }
    };
    checkExistingUser();
  }, [username]);

  return (
    <Layout className="layout">
      <AppHeader />
      <UserMenu username={username} />
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          {!username ? <UserAuth setUsername={setUsername} /> : (
            <>
              <InstagramButton />
              <ClickerButton onClick={handleClick} points={points} />
              <Leaderboard leaderboard={leaderboard} />
            </>
          )}
        </div>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default App;
