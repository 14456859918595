import React from 'react';
import { Layout, Typography, Button } from 'antd';
import { auth, signOut } from '../firebase';
import './Header.css';

const { Header } = Layout;
const { Title } = Typography;

const AppHeader = ({ username }) => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload(); // Reload the page to clear the state
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Header>
      <div className="header-content">
        <Title className="neon-text">Cania</Title>
        {username && (
          <Button type="primary" onClick={handleLogout}>
            Logout
          </Button>
        )}
      </div>
    </Header>
  );
};

export default AppHeader;
