import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, signOut } from 'firebase/auth';
import { getFirestore, doc, setDoc, updateDoc, getDoc, onSnapshot, collection } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBwjAvUU48kwvJjgU8ZlhMHm76-uz7pLCU",
    authDomain: "njcuai.firebaseapp.com",
    projectId: "njcuai",
    storageBucket: "njcuai.appspot.com",
    messagingSenderId: "216778001714",
    appId: "1:216778001714:web:d02ad92f0a9d22574f9b2b"
  };

  const app = initializeApp(firebaseConfig);

  const auth = getAuth(app);
  const firestore = getFirestore(app);
  
  export { auth, firestore, signInAnonymously, signOut, doc, setDoc, updateDoc, getDoc, onSnapshot, collection };