import React from 'react';
import { List, Typography } from 'antd';
import './Leaderboard.css';

const { Title } = Typography;

const Leaderboard = ({ leaderboard }) => (
  <div>
    <Title level={2} className="leaderboard-title">Leaderboard</Title>
    <List
      bordered
      dataSource={leaderboard.sort((a, b) => b.points - a.points).slice(0, 10)}
      renderItem={(item, index) => (
        <List.Item className={`leaderboard-item ${index === 0 ? 'leaderboard-item-top' : ''}`}>
          {index === 0 ? '👑 ' : ''}{item.name}: {item.points}
        </List.Item>
      )}
    />
  </div>
);

export default Leaderboard;
